/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 *
 * @param object
 * @param keys
 * @param defaultVal
 * https://lodash.com/docs/#get
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getProp = (object: any, keys: any, defaultVal?: any): any => {
  if (object === undefined) {
    return defaultVal;
  }
  keys = Array.isArray(keys)
    ? keys
    : keys.replace(/(\[(\d)\])/g, '.$2').split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1), defaultVal);
  }
  return object === undefined ? defaultVal : object;
};

export const pageIdFieldValue = (fieldContent: any) => {
  const NA = 'Not avaiable';

  switch (Object.prototype.toString.call(fieldContent)) {
    case '[object Object]':
      const values = Object.keys(fieldContent).filter((x: any) => x.selected);

      return values.length ? values.concat(', ') : NA;
    case '[object Array]':
      return fieldContent.join(', ');
    case '[object String]':
      return fieldContent;

    default:
      return NA;
  }
};

export const concat = (...args: string[]): string =>
  args.filter((x) => !!x).join(', ');
