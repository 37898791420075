import { useContext } from 'react';
import { Authorization, CookieSetter } from './types';
import context from './AuthenticationContext';

export default function useAuthenticationCookies(): [
  Authorization,
  CookieSetter,
] {
  return useContext(context);
}
