import {
  DocumentNode,
  OperationVariables,
  LazyQueryHookOptions,
} from '@apollo/client';
import { useState } from 'react';

interface DataType<T> {
  data?: T;
  loading?: boolean;
  called?: boolean;
}
interface LazyQueryOptions {
  variables: Record<string, unknown>;
}

interface LazyQueryFakeHookOptions<T>
  extends LazyQueryHookOptions<T, OperationVariables> {
  defaultValue: T;
}

export default function useFakeLazyQuery<T>(
  gql: DocumentNode,
  options: LazyQueryFakeHookOptions<T>,
): [(args: LazyQueryOptions) => void, DataType<T | null>] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const [called, setCalled] = useState<boolean>(false);

  const refetch = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setCalled(true);
      setData(options.defaultValue);
      options.onCompleted?.(options.defaultValue);
    }, 500);
  };

  return [refetch, { data, loading, called }];
}
