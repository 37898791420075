import { useRef } from 'react';

interface DebounceProps {
  ms?: number;
}

type DebouncerFn = (fn: () => void) => void;

export default function useDebounce({ ms }: DebounceProps = {}): DebouncerFn {
  const ref = useRef<number>();
  return (fn) => {
    clearTimeout(ref.current);
    ref.current = Number(setTimeout(() => fn(), ms ?? 1000));
  };
}
